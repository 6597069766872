import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import theme from "../styles/GlobalVariables"
import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import FeaturedImages from "../components/FeaturedImages"
import IntroParagraph from "../components/IntroParagraph"
import Hours from "../components/Hours"
import Tagline from "../components/Tagline"
//import Menu from "../components/Menu"
import TaglineTwo from "../components/TaglineTwo"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import FacebookFeed from "../components/FacebookFeed"
import { Helmet } from "react-helmet"
import Order from '../components/Order'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      facebook
      instagram
      favicon {
        asset {
          url
        }
      }
    }
    logo: sanityLogo {
      logo {
        asset {
          url
        }
      }
      alt
    }
    navigation: sanityNavigation {
      navigationItems
    }
    hero: sanityHero {
      backgroundImage {
        asset {
          url
        }
      }
      alt
      tagline
    }
    featuredImages: sanityFeaturedImages {
      images {
        image {
          asset {
            url
          }
        }
        alt
      }
    }
    introParagraph: sanityIntroParagraph {
      title
      description
    }
    hours: sanityHours {
      hours {
        days
        hoursOpen
      }
    }
    tagline: sanityTagline {
      tagline
      backgroundImage {
        asset {
          url
        }
      }
      alt
    }
    
    taglineTwo: sanityTaglineTwo {
      taglineTwo
      backgroundImage {
        asset {
          url
        }
      }
      alt
    }
    contact: sanityContact {
      title
      name
      address
      phone
      email
      googleMap
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout logo={data.logo} navigation={data.navigation}>
      <Helmet>
        <div id="fb-root"></div>
        <script
          async
          defer
          crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=857664321678895&autoLogAppEvents=1"
          nonce="LQ9l9jIp"
        ></script>
        <script async src="https://boostlysms.com/api/webform/embed?ft=7161d2c8-a4dd-40ce-93df-7f722aa8c332"></script>
      </Helmet>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        favicon={site.favicon}
      />
      <Order />
      <Hero hero={data.hero} />
      <FeaturedImages featuredImages={data.featuredImages} />
      <IntroSectionWrapper>
        <IntroParagraph introParagraph={data.introParagraph} />
        <Hours hours={data.hours} />
        <FacebookFeed />
      </IntroSectionWrapper>
      <Tagline tagline={data.tagline} />
      
      <TaglineTwo taglineTwo={data.taglineTwo} />
      <Contact contact={data.contact} />
      <Footer site={data.site} contact={data.contact} />
    </Layout>
  )
}

const IntroSectionWrapper = styled.section`
  margin: 8rem auto;

  @media ${theme.media.tablet} {
    margin: 4rem auto;
  }
`

export default IndexPage
